import { useEffect, useRef, useState } from "react";

import api from "api";
import Footer from 'components/Footer';
import HappyCabbageLogo from "components/HappyCabbageLogo";

const LoginState = {
  DEFAULT: {},
  WAITING: {},
  SUCCESS: {},
  INVALID_CREDENTIALS: {},
  UNKNOWN_ERROR: {},
}

export default function LoginPage({ onLogin }) {
  const [loginState, setLoginState] = useState(LoginState.DEFAULT);
  const [values, setValues] = useState({
    username: '',
    password: '',
  });
  const usernameRef = useRef();
  const passwordRef = useRef();

  useEffect(() => {
    usernameRef.current.focus();
  }, [])

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  const handleForgotPassword = () => {
    window.open(`/connect/#/resetpassword?u=${values.username}`, '_blank');
  }
  const handleLogin = async () => {
    setLoginState(LoginState.WAITING);
    let success;
    try {
      success = await api.login(values);
    } catch (error) {
      setLoginState(LoginState.UNKNOWN_ERROR);
      console.warn(error);
      passwordRef.current.focus();
      return;
    }
    if (success) {
      setLoginState(LoginState.SUCCESS);
      onLogin(values.username);
    } else {
      setLoginState(LoginState.INVALID_CREDENTIALS);
      passwordRef.current.focus();
    }
  }

  const disabled = loginState === LoginState.WAITING || loginState === LoginState.SUCCESS;

  return (
    <>
      <div className="landing__body">
        <form className="box">
          <HappyCabbageLogo />
          <label className="control-label">
            <span>Username</span>
            <input
              ref={usernameRef}
              value={values.username}
              name="username"
              onChange={handleChange}
              disabled={disabled}
              type="text"
              className="form-control"
            />
          </label>
          <label className="control-label">
            <span>Password</span>
            <input
              ref={passwordRef}
              value={values.password}
              name="password"
              onChange={handleChange}
              disabled={disabled}
              type="password"
              className="form-control"
            />
          </label>
          {loginState === LoginState.INVALID_CREDENTIALS && (
            <p className="banner banner-warning">Invalid credentials</p>
          )}
          {loginState === LoginState.UNKNOWN_ERROR && (
            <p className="banner banner-warning">Unknown error</p>
          )}
          <div className="box__buttons">
            <input
              value="Forgot password?"
              onClick={handleForgotPassword}
              disabled={disabled}
              type="button"
              className="btn btn-secondary box__forgot"
            />
            <input
              value="Log in"
              onClick={handleLogin}
              disabled={disabled}
              type="submit"
              className="btn btn-default box__login"
            />
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}
