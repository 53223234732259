import { useEffect, useState } from 'react';

import api from 'api';
import LoginPage from 'components/LoginPage';
import MainPage from 'components/MainPage';

export default function App() {
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState(null);

  useEffect(() => {
    let cancel = false;
    (async () => {
      const response = await api.getUsername();
      if (!cancel) {
        setLoading(false);
        setUsername(response);
      }
    })()
    return () => { cancel = true; };
  }, []);

  const handleLogin = (user) => {
    setUsername(user);
  }
  const handleLogout = () => {
    setUsername(null);
  }

  return (
    <div className="landing">
      {!loading && username === null && (
        <LoginPage onLogin={handleLogin} />
      )}
      {!loading && username !== null && (
        <MainPage username={username} onLogout={handleLogout} />
      )}
    </div>
  );
}
