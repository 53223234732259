import { createServer, Response } from "miragejs"
import Status from 'http-status-codes';

const USERNAME = 'user';
const PASSWORD = 'password';

const DEFAULT_DESCRIPTION = `
  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
  Sed vel nisi nec magna vulputate venenatis.
`;
const POLARIS_DESCRIPTION = `
  Let your customers know when you’re having an event, running a promotion,
  or when they’re running low since their last order.
`;

function makeMockApps(partialApps) {
  return partialApps.map((partial, idx) => ({
    id: idx + 1,
    guid: idx + 1,
    build_status: 1,
    title: 'Other',
    url: '#',
    description: DEFAULT_DESCRIPTION,
    ...partial,
  }));
}

const APPS = makeMockApps([
  { title: 'Sirius' },
  { title: 'Polaris', description: POLARIS_DESCRIPTION },
  {},
  {},
  {},
  // Should be filtered out by getApp()
  { title: 'Auth' },
  { title: null },
  { build_status: 0 },
]);

export default function setupRestMock() {
  const server = createServer({
    routes() {
      this.post("/__login__", (schema, request) => {
        const attrs = JSON.parse(request.requestBody);
        if (attrs.username === USERNAME && attrs.password === PASSWORD) {
          this.loggedIn = true;
          return new Response(Status.OK);
        } else {
          return new Response(Status.UNAUTHORIZED)
        }
      });
      this.get("/__logout__", () => {
        this.loggedIn = false;
        return new Response(Status.OK)
      });
      this.get("/__api__/me", () => {
        if (this.loggedIn) {
          return { username: USERNAME };
        } else {
          return new Response(Status.UNAUTHORIZED);
        }
      });
      this.post('/__api__/me/password', (schema, request) => {
        if (this.loggedIn) {
          const attrs = JSON.parse(request.requestBody);
          if (attrs.password === PASSWORD) {
            return new Response(Status.OK);
          } else {
            return new Response(Status.FORBIDDEN)
          }
        } else {
          return new Response(Status.UNAUTHORIZED);
        }

      });
      this.get('/__api__/applications', () => {
        if (this.loggedIn) {
          return { applications: APPS };
        } else {
          return new Response(Status.UNAUTHORIZED);
        }
      });
    },
  });
  server.loggedIn = false;
  return server;
}
