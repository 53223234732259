const EXCLUDED_APPS = ['auth'];

function getTileImage(guid) {
  if (process.env.NODE_ENV === 'production') {
    return `/__api__/applications/${guid}/image`;
  } else {
    return 'logo/happy-cabbage.png';
  }
}

export default function getApp(item) {
  const { guid, build_status, title } = item;
  if (!guid || !build_status || !title) {
    return null;
  }
  if (EXCLUDED_APPS.includes(title.toLowerCase())) {
    return null;
  }
  return {
    ...item,
    tile_image: getTileImage(guid),
    tile_color: 'green',
  }
}
