import { useEffect, useRef, useState } from "react";

import api from "api";

const MIN_PASSWORD_LENGTH = 6;

const FORM_STATE_DEFAULT = 'DEFAULT';
const FORM_STATE_WAITING = 'WAITING';
const FORM_STATE_SUCCESS = 'SUCCESS';
const FORM_STATE_ERROR = 'ERROR';

const INITIAL_VALUES = {
  password: '',
  new_password: '',
  new_password_again: '',
}

export default function PasswordChangeForm({ onCancel }) {
  const [formState, setFormState] = useState(FORM_STATE_DEFAULT);
  const [values, setValues] = useState(INITIAL_VALUES);
  const [message, setMessage] = useState(null);
  const passwordRef = useRef();
  const newPasswordRef = useRef();

  useEffect(() => {
    passwordRef.current.focus();
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (values.new_password !== values.new_password_again) {
      setFormState(FORM_STATE_ERROR);
      setMessage('Passwords must match exactly');
      newPasswordRef.current.focus();
      return;
    }
    if (values.new_password.length < MIN_PASSWORD_LENGTH) {
      setFormState(FORM_STATE_ERROR);
      setMessage(`The new password must be at least ${MIN_PASSWORD_LENGTH} characters long`)
      newPasswordRef.current.focus();
      return;
    }
    setFormState(FORM_STATE_WAITING);
    let success;
    try {
      success = await api.changePassword(values);
    } catch (error) {
      setFormState(FORM_STATE_ERROR);
      setMessage('Unknown error, please try again');
      console.warn(error);
      return;
    }
    if (success) {
      setFormState(FORM_STATE_SUCCESS);
      setMessage('Password changed successfully');
      setValues(INITIAL_VALUES);
    } else {
      setFormState(FORM_STATE_ERROR);
      setMessage('Invalid current password');
      passwordRef.current.focus();
    }
  }

  return (
    <div className="landing__body">
      <form className="box box--password">
        <fieldset disabled={formState === FORM_STATE_WAITING}>
          <h5 className="box__heading">Change password</h5>
          <label className="control-label">
            <span>Current password</span>
            <input
              ref={passwordRef}
              value={values.password}
              name="password"
              onChange={handleChange}
              type="password"
              className="form-control"
            />
          </label>
          <label className="control-label">
            <span>New password</span>
            <input
              ref={newPasswordRef}
              value={values.new_password}
              name="new_password"
              onChange={handleChange}
              type="password"
              className="form-control"
            />
          </label>
          <label className="control-label">
            <span>New password (again)</span>
            <input
              value={values.new_password_again}
              name="new_password_again"
              onChange={handleChange}
              type="password"
              className="form-control"
            />
          </label>
          {formState === FORM_STATE_SUCCESS && <p className="banner banner-success">{message}</p>}
          {formState === FORM_STATE_ERROR && <p className="banner banner-warning">{message}</p>}
          <div className="box__buttons">
            <input
              value="Cancel"
              onClick={onCancel}
              type="button"
              className="btn btn-secondary box__forgot"
            />
            <input
              value="Change password"
              onClick={handleSubmit}
              type="submit"
              className="btn btn-default box__login"
            />
          </div>
        </fieldset>
      </form>
    </div>
  );
}
