import { useRef, useState } from "react";

import api from "api";
import HappyCabbageLogo from "components/HappyCabbageLogo";
import ContentList from "./ContentList";
import Footer from 'components/Footer';
import PasswordChangeForm from "./PasswordChangeForm";

export default function MainPage({ username, onLogout }) {
  const [showContent, setShowContent] = useState(true);
  const changeViewRef = useRef();

  const handleLogout = () => {
    api.logout();
    onLogout();
  }

  const handleViewChange = () => {
    setShowContent(!showContent);
    changeViewRef.current.blur();
  }

  return (
    <>
      <div className="landing__header">
        <HappyCabbageLogo />
        <h3 className="username">
          {`Hello ${username}!`}
        </h3>
        <button
          ref={changeViewRef}
          onClick={handleViewChange}
          className="btn btn-secondary"
        >
          {showContent ? 'Change password' : 'View apps'}
        </button>
        <button
          onClick={handleLogout}
          className="btn btn-secondary"
        >
          Log out
        </button>
      </div>
      {showContent ? <ContentList /> : <PasswordChangeForm onCancel={handleViewChange} />}
      <Footer type="main" />
    </>
  )
}
