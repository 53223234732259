export default function HappyCabbageLogo() {
  return (
    <span className="logo">
      <img
        src="logo/happy-cabbage.png"
        className="logo logo__img"
        alt="Happy Cabbage Logo"
      />
    </span>
  );
}
