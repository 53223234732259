export default function Footer({ type = null }) {
  const footerClass = `landing__footer${type ? ` landing__footer--${type}` : ''}`;

  return (
    <div className={footerClass}>
      <div className="footer">
        <p className="footer__copy">© Happy Cabbage Analytics, Inc. 2020</p>
        <p className="footer__issues">
          {'Having issues? '}
          <a className="footer__link" href="/">Try this page</a>
        </p>
      </div>
    </div>
  );
}
